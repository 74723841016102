export function toPlatformName(platform: string): string {
  switch (platform) {
    case 'pc': return 'PC';
    case 'playstation': return 'PlayStation';
    case 'xbox': return 'Xbox';
    case 'switch': return 'Nintendo Switch';
    default: return 'other';
  }
}

export function toPlayStyle(style: string): string {
  switch (style) {
    case 'main': return 'Main story only';
    case 'main+extra': return 'Main story and side missions';
    case 'completionist': return 'Complete game for 100%';
    default: return null;
  }
}