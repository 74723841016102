import React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import logo from "../assets/logo.svg";
import walking from "../assets/walking.gif";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";

const styles = () => createStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  logo: {
    height: 200,
  },
  coming: {
    display: 'flex',
    alignItems: 'center',
    color: '#fbad50',
  },
  walking: {
    height: 50,
  },
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {
}

class Home extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const classes = this.props.classes;
    return <div className={classes.root}>
      <div className={classes.content}>
        <img src={logo} alt="logo" className={classes.logo}/>
        <div className={classes.coming}>
          <Typography variant="h5">
            is coming...
          </Typography>
          <img src={walking} alt="walking man" className={classes.walking}/>
        </div>
      </div>
    </div>
  };
}

export default withStyles(styles)(withRouter(Home));
