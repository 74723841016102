import { GameResponse } from '../services/dto/game';
import { UserResponse } from '../services/dto/user';

export function gamePlayTimeInHours(game: GameResponse, type: string): number {
  const { gameplayMain, gameplayMainExtra, gameplayCompletionist } = game;
  switch (type) {
    case 'main':
      return gameplayMain !== 0
        ? gameplayMain
        : gameplayMainExtra !== 0 ? gameplayMainExtra : gameplayCompletionist;
    case 'main+extra':
      return gameplayMainExtra !== 0
        ? gameplayMainExtra
        : gameplayMain !== 0 ? gameplayMain : gameplayCompletionist;
    case 'completionist':
      return gameplayCompletionist !== 0
        ? gameplayCompletionist
        : gameplayMainExtra !== 0 ? gameplayMainExtra : gameplayMain;
  }
  return 0;
}

function playTimeHoursInDay(user: UserResponse): number {
  const { playTime, playTimeMeasurement, playTimePeriod } = user;
  const time = playTimeMeasurement === 'hours' ? playTime : playTime / 60.0;
  return playTimePeriod === 'week' ? time / 7.0 : time;
}

export function calculateDaysToComplete(user: UserResponse, game: GameResponse): number {
  if (game == null) {
    return 0;
  }

  const { playStyle } = user;

  const gameTimeInHours = gamePlayTimeInHours(game, playStyle);
  const playTimeInHoursInDay = playTimeHoursInDay(user);
  return playTimeInHoursInDay !== 0 ? gameTimeInHours / playTimeInHoursInDay : 0;
}