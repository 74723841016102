import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import { createMuiTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { pink, purple } from '@material-ui/core/colors'
import Profile from './components/Profile';

const theme = createMuiTheme({
  palette: {
    primary: purple,
    secondary: pink,
    background: {
      default: '#eee'
    }
  }
});

const App = () => {
  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <CssBaseline/>
        <Router>
          <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={'/p/:hash'} component={Profile}/>
          </Switch>
        </Router>
      </MuiThemeProvider>
    </React.Fragment>
  );
};

export default App;
