import React from 'react';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import CardContent from '@material-ui/core/CardContent';
import {
  AppBar,
  CardHeader,
  CardMedia,
  Chip,
  CircularProgress,
  Container,
  Grid,
  Link,
  Toolbar,
  Typography
} from '@material-ui/core';
import logoFull from '../assets/logo-full.svg';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from "react-router";
import { GamometerApi } from '../services/gamometer.api';
import { UserGameResponse } from '../services/dto/game';
import { UserResponse } from '../services/dto/user';
import Gravatar from 'react-gravatar';
import { toPlatformName, toPlayStyle } from '../utils/dictionary';
import { calculateDaysToComplete } from '../utils/calculations';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    paddingTop: 20,
    paddingBottom: 20,
    minHeight: 700,
  },
  header: {
    maxWidth: 960,
    margin: '0 auto',
    backgroundColor: '#777',
  },
  logoLink: {
    display: 'flex',
    alignSelf: 'center',
  },
  logo: {
    height: 45
  },
  title: {
    marginLeft: 10,
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
  },
  gamesHeader: {
    marginTop: 20,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: theme.spacing(5),
  },
  card: {
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    height: '100%',
    alignItems: 'flex-start',
  },
  rowInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignContent: 'center',
  },
  rowInfoItem: {
    alignSelf: 'center',
    marginRight: 10,
    paddingTop: 5,
  },
  media: {
    height: 0,
    paddingTop: '120%',
  },
  statistic: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  line: {
    margin: '2px 0',
  },
  chip: {
    marginLeft: 5,
  }
});

interface Props extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface State {
  loading: boolean,
  user: UserResponse,
  games: UserGameResponse[],
}

class Profile extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      games: [],
    };
  }

  async componentDidMount() {
    try {
      const { hash } = this.props.match.params;
      const [user, games] = await Promise.all([
        GamometerApi.getUserByHash(hash),
        GamometerApi.getGamesByUserHash(hash),
      ]);

      this.setState({
        loading: false,
        user: user.data,
        games: games.data,
      })
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { user, games, loading } = this.state;
    return (<>
        <AppBar position="static" elevation={1} className={classes.header}>
          <Toolbar>
            <Link href="/" className={classes.logoLink}>
              <img className={classes.logo} src={logoFull} alt={'Logo'}/>
            </Link>
            <Typography variant="h5" className={classes.title}>
              GAMOMETER
            </Typography>
          </Toolbar>
        </AppBar>
        <Container maxWidth={'md'} className={classes.root}>
          {
            loading
              ? (<CircularProgress/>)
              : user ? this.renderProfile(user, games)
              : (<Typography variant="h4">User not found...</Typography>)
          }
        </Container>
      </>
    );
  }

  private renderProfile(user: UserResponse, games: UserGameResponse[]) {
    const { classes } = this.props;
    const totalDays = games.reduce((total, ug) => total + calculateDaysToComplete(user, ug.game), 0)

    return <Grid container alignItems='stretch' spacing={2}>
      <Grid item md={3} xs={12} className={classes.profile}>
        <Gravatar
          email={user.email}
          size={200}
          rating="pg"
          default="mm"
          className={classes.avatar}/>
        <Typography>
          {`${user.firstName} ${user.lastName}`}
        </Typography>
      </Grid>
      <Grid item md={9} xs={12} className={classes.statistic}>
        <div className={classes.line}>
          Play Time: {user.playTime} {user.playTimeMeasurement} a {user.playTimePeriod}
        </div>
        <div className={classes.line}>
          Platforms: {user.platforms.map(platform =>
          (<Chip className={classes.chip} size="small" key={platform} label={toPlatformName(platform)}/>)
        )}
        </div>
        <div className={classes.line}>
          Style: <Chip size="small" label={toPlayStyle(user.playStyle)}/>
        </div>
        <div className={classes.line}>
          Time to complete: <Chip size="small" style={{ backgroundColor: '#f75496', color: '#fff' }}
                                  label={`${Math.ceil(totalDays)}`}/> day(s)
        </div>
      </Grid>
      <Grid item xs={12} md={12} className={classes.gamesHeader}>
        <Typography variant="h6" gutterBottom>
          Remaining games ({games.length})
        </Typography>
      </Grid>
      {games.map(this.renderGame)}
    </Grid>;
  }

  private renderGame = (ug: UserGameResponse) => {
    const { user } = this.state;
    const { game } = ug;
    const classes = this.props.classes;
    const daysToComplete = calculateDaysToComplete(user, game);

    return <Grid key={game.id} item md={3} sm={4} xs={6}>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={game.name}
          titleTypographyProps={{ variant: 'subtitle1' }}
        />
        <Link href={`https://howlongtobeat.com/game?id=${game.hltbId}`} target="_blank">
          <CardMedia
            className={classes.media}
            image={game.imageUrl}
            title={game.name}
          />
        </Link>
        <CardContent>
          <div className={classes.rowInfo}>
            <TimerOutlinedIcon className={classes.rowInfoItem}/>
            <div className={classes.rowInfoItem}>{Math.ceil(daysToComplete)} day(s)</div>
          </div>
          <div className={classes.rowInfo}>
            <AssignmentTurnedInOutlinedIcon className={classes.rowInfoItem}/>
            <div className={classes.rowInfoItem}>{ug.percentageCompleted}% completed</div>
          </div>
        </CardContent>
      </Card>
    </Grid>;
  }
}

export default withStyles(styles)(withRouter(Profile));
